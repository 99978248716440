'use client';

import React from 'react';
import Link from 'next/link';

import { Button } from 'components/ui/button';
import { GoogleIcon } from 'components/icons';

import { OAuthLink, OAuthProvider } from 'generated/types';
import { EventAuthType, useAuthTracking } from 'analytics/events';

type OAuthButtonProps = {
  link: OAuthLink
}

export const GetOAuthButton = (props: OAuthButtonProps) => {
  const { trackAuth } = useAuthTracking();

  switch (props.link.provider) {
    case OAuthProvider.Google:
      return (
        <Button asChild type='button' variant='nakedGray' size='medium' className='gap-3'>
          <Link href={props.link.link} onClick={() => trackAuth(EventAuthType.OAuth, 'started')}>
            <GoogleIcon />
            Sign in with Google
          </Link>
        </Button>
      );
  }
};
